import {
  addColumns,
  createTable,
  schemaMigrations,
} from '@nozbe/watermelondb/Schema/migrations';

import { answerTable } from '../schemas/answerSchema';
import { audioTable } from '../schemas/audioSchema';
import { conditionalTable } from '../schemas/conditionalSchema';
import { formTable } from '../schemas/formSchema';
import { interviewTable } from '../schemas/interviewSchema';
import { operationalSessionTable } from '../schemas/operationalSessionSchema';
import { projectTable } from '../schemas/projectSchema';
import { questionTable } from '../schemas/questionSchema';
import { quotaTable } from '../schemas/quotaSchema';
import { sampleTable } from '../schemas/sampleSchema';
import { sampleToAnswerTable } from '../schemas/sampleToAnswerSchema';
import { stepTable } from '../schemas/stepSchema';
import { typeAnswerTable } from '../schemas/typeAnswerSchema';

export default schemaMigrations({
  migrations: [
    {
      toVersion: 2,
      steps: [
        createTable(conditionalTable),
        createTable(formTable),
        createTable(operationalSessionTable),
        createTable(projectTable),
        createTable(questionTable),
        createTable(quotaTable),
        createTable(sampleTable),
        createTable(sampleToAnswerTable),
        createTable(stepTable),
        createTable(typeAnswerTable),
      ],
    },
    {
      toVersion: 3,
      steps: [createTable(interviewTable), createTable(answerTable)],
    },
    {
      toVersion: 4,
      steps: [],
    },
    {
      toVersion: 5,
      steps: [
        addColumns({
          table: 'type_answers',
          columns: [
            {
              name: 'order',
              type: 'number',
            },
          ],
        }),
      ],
    },
    {
      toVersion: 6,
      steps: [
        addColumns({
          table: 'interviews',
          columns: [
            {
              name: 'is_discarted',
              type: 'boolean',
            },
          ],
        }),
        addColumns({
          table: 'type_answers',
          columns: [
            {
              name: 'is_unique',
              type: 'boolean',
            },
          ],
        }),
      ],
    },
    {
      toVersion: 7,
      steps: [
        addColumns({
          table: 'conditionals',
          columns: [{ name: 'order', type: 'number' }],
        }),
      ],
    },
    {
      toVersion: 8,
      steps: [
        addColumns({
          table: 'answers',
          columns: [
            { name: 'session_id', type: 'string' },
            { name: 'form_id', type: 'number' },
            { name: 'form_version', type: 'string' },
            { name: 'is_discarted', type: 'boolean' },
          ],
        }),
      ],
    },
    {
      toVersion: 9,
      steps: [
        addColumns({
          table: 'questions',
          columns: [{ name: 'is_dropdown', type: 'boolean' }],
        }),
      ],
    },
    {
      toVersion: 10,
      steps: [
        addColumns({
          table: 'questions',
          columns: [{ name: 'is_random_order_answer', type: 'boolean' }],
        }),
      ],
    },
    {
      toVersion: 11,
      steps: [
        addColumns({
          table: 'type_answers',
          columns: [{ name: 'pin_answer', type: 'boolean' }],
        }),
      ],
    },
    {
      toVersion: 12,
      steps: [createTable(audioTable)],
    },
    {
      toVersion: 13,
      steps: [
        addColumns({
          table: 'interviews',
          columns: [{ name: 'sample_id', type: 'number' }],
        }),
      ],
    },
    {
      toVersion: 14,
      steps: [
        addColumns({
          table: 'questions',
          columns: [{ name: 'answer_limit', type: 'number' }],
        }),
      ],
    },
    {
      toVersion: 15,
      steps: [
        addColumns({
          table: 'steps',
          columns: [{ name: 'total_questions', type: 'number' }],
        }),
      ],
    },
    {
      toVersion: 16,
      steps: [
        addColumns({
          table: 'questions',
          columns: [{ name: 'answer_limit', type: 'number' }],
        }),
      ],
    },
    {
      toVersion: 17,
      steps: [
        addColumns({
          table: 'audios',
          columns: [{ name: 'synced', type: 'boolean' }],
        }),
      ],
    },
  ],
});
