/* eslint-disable no-promise-executor-return */
/* eslint-disable no-await-in-loop */
import { API_BASE_URL, BLOB_READ_WRITE_TOKEN } from '@env';
import { put } from '@vercel/blob';
import axios from 'axios';
import type { ReactNode } from 'react';
import { createContext, useCallback, useContext, useState } from 'react';

import { database } from '@/database';
import type { AudioModel } from '@/database/model/Audio';
import { syncAnswers } from '@/services/Synchronize';

interface IContext {
  children: ReactNode;
}

interface ISyncContext {
  isSyncing: boolean;
  setSync: () => Promise<boolean>;
  isModalVisible: boolean;
  handleShowIsModalVisible: (_visible: boolean) => void;
  lastUpdate: Date | undefined;
  cancelIsSyncing: () => void;
  totalAudios: number;
  syncedAudiosCount: number;
}

export const SyncContext = createContext({} as ISyncContext);
const axiosInstance = axios.create({ baseURL: API_BASE_URL });
const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;

export default function SyncContextProvider({ children }: IContext) {
  const [isSyncing, setIsSyncing] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [lastUpdate, setLastUpdate] = useState<Date>();
  const [totalAudios, setTotalAudios] = useState(0);
  const [syncedAudiosCount, setSyncedAudiosCount] = useState(0);

  const retryRequest = async (
    fn: () => Promise<any>,
    retries = MAX_RETRIES,
    delay = RETRY_DELAY,
  ): Promise<any> => {
    for (let attempt = 0; attempt < retries; attempt += 1) {
      try {
        return await fn();
      } catch (error) {
        console.error(`Attempt ${attempt + 1} failed:`, error);
        if (attempt < retries - 1) {
          await new Promise((resolve) =>
            setTimeout(resolve, delay * 2 ** attempt),
          ); // Exponential backoff
        } else {
          throw error;
        }
      }
    }
    throw new Error('Max retries reached');
  };

  const syncAudios = async () => {
    const audios = await database.get<AudioModel>('audios').query();
    setTotalAudios(audios.length);
    const asyncedAudios = audios.filter((audio) => !audio.synced);
    setSyncedAudiosCount(audios.length - asyncedAudios.length);
    const syncPromises = asyncedAudios.map(async (audio) => {
      const plainAudio = {
        interview_id: audio.interview_id,
        created_by: audio.created_by,
        updated_by: audio.updated_by,
        audio: audio.audio,
        created_at: audio.created_at,
        updated_at: audio.updated_at,
      };
      const { url } = await retryRequest(() =>
        put(`${plainAudio.interview_id}.txt`, plainAudio.audio, {
          access: 'public',
          token: BLOB_READ_WRITE_TOKEN,
        }),
      );
      const response = await retryRequest(() =>
        axiosInstance.post(
          '/audio/save',
          JSON.stringify({ ...plainAudio, audio: url }),
        ),
      );
      if (response.status === 201) {
        database.write(async () => {
          audio.update((record) => {
            // eslint-disable-next-line no-param-reassign
            record.synced = true;
          });
        });
        setSyncedAudiosCount((prevState) => prevState + 1);
      }
      return response;
    });

    try {
      await Promise.all(syncPromises);
    } catch (error) {
      console.error('Erro ao sincronizar os áudios:', error);
    }
  };

  const handleShowIsModalVisible = useCallback((visible: boolean) => {
    setIsModalVisible(visible);
  }, []);

  const cancelIsSyncing = () => {
    setIsSyncing(false);
  };

  const setSync = async () => {
    if (!isSyncing) {
      setIsSyncing(true);
      await syncAnswers();
      await syncAnswers();
      syncAudios();
      setIsSyncing(false);
      setLastUpdate(new Date());
      return true;
    }
    return false;
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    isSyncing,
    setSync,
    isModalVisible,
    handleShowIsModalVisible,
    lastUpdate,
    cancelIsSyncing,
    totalAudios,
    syncedAudiosCount,
  };

  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <SyncContext.Provider value={value}>{children}</SyncContext.Provider>
  );
}

export const useSyncContext = () => useContext(SyncContext);
