/* eslint-disable no-underscore-dangle */
import { Q } from '@nozbe/watermelondb';
import { useIsFocused } from '@react-navigation/core';
import React, { useEffect, useState } from 'react';
import { Text, View } from 'react-native';

import { Header } from '@/components/Header';
import { SafeAreaView } from '@/components/SafeAreaView';
import { useSyncContext } from '@/contexts/syncContext';
import { database } from '@/database';
import type { FormModel } from '@/database/model/Form';
import type { InterviewModel } from '@/database/model/Interview';
import type { IRawInterview, ISyncList } from '@/libs/types/sync';

import { Card } from './components/Card';
import { AudioCard } from './components/Card/Audio';
import { Table } from './components/Table';
import { styles } from './styles';

export function Dashboard() {
  const isFocused = useIsFocused();
  const { isSyncing } = useSyncContext();

  const [interviews, setInterviews] = useState<ISyncList[]>([]);

  useEffect(() => {
    const getInterviews = async () => {
      const interviewsData = await database
        .get<InterviewModel>('interviews')
        .query(Q.sortBy('created_at', Q.desc));
      const data = await Promise.all(
        interviewsData.map(async (interview) => {
          const form = await database
            .get<FormModel>('forms')
            .query(
              Q.and(
                Q.where('form_id', interview.form_id),
                Q.where('form_version', interview.form_version),
              ),
            )
            .fetch();
          return {
            ...(interview._raw as IRawInterview),
            form_name: form[0]?.name,
          };
        }),
      );
      setInterviews(
        data.filter((interview) => {
          if (interview._status === 'synced') {
            return interview.interview_id !== 0;
          }
          return interview;
        }),
      );
    };

    getInterviews();
  }, [isFocused, isSyncing]);

  return (
    <SafeAreaView>
      <Header />

      <View style={styles.container}>
        <View style={styles.header}>
          <View style={styles.infoScreen}>
            <Text style={styles.name}>Dashboard</Text>
            <Text style={styles.title}>Acompanhamento de entrevistas</Text>
          </View>
        </View>

        <View style={styles.dashboardContainer}>
          <Card interviews={interviews} />
          <AudioCard />
          <Table interviews={interviews} />
        </View>
      </View>
    </SafeAreaView>
  );
}
