import React, { memo } from 'react';
import { Text, useWindowDimensions, View } from 'react-native';

import { useSyncContext } from '@/contexts/syncContext';

import { styles } from './styles';

function AudioCardComponent() {
  const { totalAudios, syncedAudiosCount } = useSyncContext();
  const { width } = useWindowDimensions();
  const isMobile = width < 540;

  return (
    <View style={[styles.container, isMobile && styles.containerMobile]}>
      <View
        style={[styles.cardContainer, isMobile && styles.cardContainerMobile]}
      >
        <Text style={styles.number}>
          {syncedAudiosCount} / {totalAudios}
        </Text>
        <Text style={styles.label}>Audios sincronizados</Text>
      </View>
    </View>
  );
}

export const AudioCard = memo(AudioCardComponent);
