import { tableSchema } from '@nozbe/watermelondb';

import type { ITable } from './interface';

export const audioTable = {
  name: 'audios',
  columns: [
    {
      name: 'audio',
      type: 'string',
    },
    {
      name: 'interview_id',
      type: 'string',
    },
    {
      name: 'created_by',
      type: 'string',
    },
    {
      name: 'updated_by',
      type: 'string',
    },
    {
      name: 'created_at',
      type: 'number',
    },
    {
      name: 'updated_at',
      type: 'number',
    },
    {
      name: 'synced',
      type: 'boolean',
    },
  ],
} as ITable;

export const audioSchema = tableSchema(audioTable);
